import React from 'react'
import Layout from '../components/layouts/Layout'

import styled from 'styled-components'
import { InfoPagesWrapper } from '../components/layouts'
import SEO from '../components/seo'
import { Helmet } from 'react-helmet'
import { below } from '../components/utilities'
import { Card, ExtLinks } from "../components/elements"
// import Image from "../components/image"\

const ContactWrapper = styled(InfoPagesWrapper)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  //grid-gap: 4rem;
  align-items: center;
  ${below.med`grid-template-columns:1fr;`};
  //text-align: center;
  //height: 90vh;
  margin-bottom: 3rem;
  p,
  h3,
  a {
    text-align: center;
    margin-bottom: 2rem;
  }
`
const variants = {
  visible: { opacity: 1, x: 0, transition: { type: 'spring', duration: 1.5 } },
  hidden: { opacity: 0, x: -300 },
}

const Contact = () => {
  //   const data = useStaticQuery(graphql`
  //     query SiteTitleQuery {
  //         file(relativePath:{ eq: "Tree.svg" }){
  //         childImageSharp {
  //         fluid(maxWidth:1000) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <>
      <Layout>
        <Helmet>
          {/*<script*/}
          {/*  id="setmore_script"*/}
          {/*  type="text/javascript"*/}
          {/*  src="https://my.setmore.com/webapp/js/src/others/setmore_iframe.js"*/}
          {/*></script>*/}
        </Helmet>
        <SEO title="Contact" keywords={["Contact TC Web Design, Contact Web Designer Ireland"]} />

        {/*<Img fluid={data.file.childImageSharp.fluid}/>*/}
        <ContactWrapper>
          <h1>Get in touch!</h1>
          <hr />
          {/*<img src={Tree} />*/}
          <Card
            initial="hidden"
            animate="visible"
            variants={variants}
            css={`
              margin: 2rem auto;
            `}
          >
            <h3>Email</h3>
            <ExtLinks href="mailto: info@tcwebdesign.ie">info@tcwebdesign.ie</ExtLinks>
          </Card>
          <Card
            initial="hidden"
            animate="visible"
            variants={variants}
            css={`
              margin: 2rem auto;
            `}
          >
            <h3>Phone</h3>
            <ExtLinks href="tel:+353-87-703-1412">+353-87-703-1412</ExtLinks>
          </Card>{' '}
        </ContactWrapper>
      </Layout>
    </>
  )
}

export default Contact
